import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import "./FeaturedIn.css";

import Section from "../Section/Section";
import SectionHeading from "../SectionHeading/SectionHeading";

const featuredIns = [
  {
    image: (
      <StaticImage
        key="featured-in-1"
        src={"../../images/featured-in-1.png"}
        alt="Daily Express"
        className="featured-in-logo"
        placeholder="blurred"
        layout="fixed"
        width={200}
        height={86}
        loading="lazy"
      ></StaticImage>
    ),
  },
  {
    image: (
      <StaticImage
        key="featured-in-2"
        src={"../../images/featured-in-2.png"}
        alt="The New Zealand Herald"
        className="featured-in-logo"
        placeholder="blurred"
        layout="fixed"
        width={300}
        height={50}
        loading="lazy"
      ></StaticImage>
    ),
  },
  {
    image: (
      <StaticImage
        key="featured-in-4"
        src={"../../images/featured-in-4.svg"}
        alt="Travel Weekly Asia"
        className="featured-in-logo"
        placeholder="blurred"
        layout="fixed"
        width={300}
        height={50}
        loading="lazy"
      ></StaticImage>
    ),
  },
  {
    image: (
      <StaticImage
        key="featured-in-5"
        src={"../../images/featured-in-5.png"}
        alt="Daily Star"
        className="featured-in-logo"
        placeholder="blurred"
        layout="fixed"
        width={300}
        height={100}
        loading="lazy"
      ></StaticImage>
    ),
  },
  {
    image: (
      <StaticImage
        key="featured-in-6"
        src={"../../images/featured-in-6.png"}
        alt="British News Today"
        className="featured-in-logo"
        placeholder="blurred"
        layout="fixed"
        width={300}
        height={63}
        loading="lazy"
      ></StaticImage>
    ),
  },
  {
    image: (
      <StaticImage
        key="featured-in-9"
        src={"../../images/featured-in-9.png"}
        alt="In The Snow"
        className="featured-in-logo"
        placeholder="blurred"
        layout="fixed"
        width={200}
        height={97}
        loading="lazy"
      ></StaticImage>
    ),
  },
  {
    image: (
      <StaticImage
        key="featured-in-10"
        src={"../../images/featured-in-10.png"}
        alt="Samachar Central"
        className="featured-in-logo"
        placeholder="blurred"
        layout="fixed"
        width={300}
        height={42}
        loading="lazy"
      ></StaticImage>
    ),
  },
  {
    image: (
      <StaticImage
        key="featured-in-11"
        src={"../../images/featured-in-11.svg"}
        alt="Connecting Travel"
        className="featured-in-logo"
        placeholder="blurred"
        layout="fixed"
        width={300}
        height={23}
        loading="lazy"
      ></StaticImage>
    ),
  },
  {
    image: (
      <StaticImage
        key="featured-in-15"
        src={"../../images/featured-in-15.png"}
        alt="Wanderlust"
        className="featured-in-logo"
        placeholder="blurred"
        layout="fixed"
        width={200}
        height={52}
        loading="lazy"
      ></StaticImage>
    ),
  },
];

export default function FeaturedIn() {
  return (
    <Section backgroundColor="#f7f7f7">
      <div className="featured-in-section">
        <SectionHeading component="h2">FEATURED IN</SectionHeading>
        <div className="featured-in-images">
          {featuredIns.map((featuredIn) => featuredIn.image)}
        </div>
      </div>
    </Section>
  );
}
